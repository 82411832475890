import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
  ConsentType,
  Currency,
  InstalmentScheduleRequestObject,
} from "@gocardless/api/dashboard/types";
import { getTranslatedPaymentPeriodicityMessage } from "src/common/frequency";
import { ReactNode } from "react";
import { PaymentVariant } from "src/common/payments";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";
import { format } from "date-fns";

import { getDefaultMandateDescription } from "../GenericMandateDescription";
import { IMandateCurrencySelector as CurrencySelectorProps } from "../components/MandateCurrencySelector";
import InstalmentTemplateDescription from "../../InstalmentTemplateDescription";

import { DescriptionTemplate } from "./DescriptionTemplate";

const getFirstInstalmentDate = (
  instalments: InstalmentScheduleRequestObject["instalments"]
) => {
  if (!instalments) {
    return;
  }
  if (Array.isArray(instalments)) {
    return new Date(instalments[0]["charge_date"]);
  }
  return new Date(instalments["charge_date"]);
};

const getDisplayedValues = (
  billingRequest: BillingRequestResource,
  defaultMandateDescription: ReactNode
) => {
  const scheme =
    (billingRequest.mandate_request?.scheme as AvailableDebitSchemeEnum) ??
    undefined;
  const { consentTypeText, toolTipContent, instalmentConsentTypeText } =
    getDisplayedConsentInfo(
      scheme,
      billingRequest.mandate_request?.consent_type as ConsentType
    );
  const baseValues = {
    scheme,
    displayedConsentType: consentTypeText,
    toolTipContent: toolTipContent,
    paymentMethodDescription:
      billingRequest.mandate_request?.constraints?.payment_method,
    mandateDescription:
      billingRequest.mandate_request?.description ?? defaultMandateDescription,
    currency: billingRequest.mandate_request?.currency as Currency,
    creditorName: billingRequest.creditor_name,
    amount: undefined,
    periodicityMessage: undefined,
  };

  if (billingRequest.subscription_request) {
    return {
      ...baseValues,
      heading: billingRequest.subscription_request?.name,
      mandateDescription: undefined,
      amount: parseInt(
        billingRequest.subscription_request?.amount?.toString() || "0"
      ),
      recurringPaymentsCount:
        billingRequest.subscription_request?.count ?? undefined,
      periodicityMessage: getTranslatedPaymentPeriodicityMessage(
        billingRequest.subscription_request
      ),
    };
  }

  if (billingRequest.instalment_schedule_request) {
    const instalmentDate = getFirstInstalmentDate(
      billingRequest.instalment_schedule_request.instalments
    );
    const firstPaymentDate = instalmentDate
      ? format(instalmentDate, "'the' do 'of' MMMM")
      : undefined;

    return {
      ...baseValues,
      displayedConsentType: instalmentConsentTypeText,
      heading: billingRequest.instalment_schedule_request?.name,
      mandateDescription: undefined,
      amount: parseInt(
        billingRequest.instalment_schedule_request.total_amount?.toString() ||
          "0"
      ),
      instalmentPaymentsCount:
        billingRequest.instalment_schedule_request.instalments?.length ??
        undefined,
      periodicityMessage: <>First installment billed on {firstPaymentDate}</>,
    };
  }

  return baseValues;
};

export const ConsentTypeDescription = ({
  billingRequest,
  paymentVariant,
  showCurrencySelector,
  currencySelectorProps,
}: {
  billingRequest: BillingRequestResource;
  paymentVariant: PaymentVariant;
  showCurrencySelector: boolean;
  currencySelectorProps: CurrencySelectorProps;
}) => {
  const instalmentScheduleRequest = billingRequest.instalment_schedule_request;

  const defaultMandateDescription = getDefaultMandateDescription(
    billingRequest,
    paymentVariant
  );

  const displayValues = getDisplayedValues(
    billingRequest,
    defaultMandateDescription
  );

  if (instalmentScheduleRequest) {
    return (
      <InstalmentTemplateDescription
        instalmentTemplate={instalmentScheduleRequest}
        billingRequest={billingRequest}
        {...displayValues}
      />
    );
  }

  return (
    <DescriptionTemplate
      currencySelectorProps={currencySelectorProps}
      showCurrencySelector={showCurrencySelector}
      {...displayValues}
    />
  );
};
