import { ConsentType } from "@gocardless/api/dashboard/types";
import { FontWeight, Text } from "@gocardless/flux-react";
import { ReactNode } from "react";
import { Scheme, SchemesWithConsentParameters } from "src/common/scheme";

type BaseDisplayedConsentInformationType = {
  [key in ConsentType]: {
    toolTipContent: ReactNode;
    consentTypeText?: ReactNode;
    instalmentConsentTypeText?: ReactNode;
    defaultDescriptionText?: ReactNode;
    consentTypeContentText?: ReactNode;
  };
};

type DisplayedConsentInformationType = {
  [key in SchemesWithConsentParameters]: BaseDisplayedConsentInformationType;
};

// TODO: Add translations

const baseDisplayedConsentInformation: BaseDisplayedConsentInformationType = {
  [ConsentType.OneOff]: {
    consentTypeContentText: <>one-time ACH Debit Authorization</>,
    consentTypeText: <>One-time payment</>,
    toolTipContent: (
      <Text>
        A <Text weight={FontWeight.Bold}>One-time payment</Text> is a debit
        initiated by a merchant based on the payer&apos;s authorization for a
        one-time transfer of funds from the payer&apos;s account.
      </Text>
    ),
  },
  [ConsentType.Recurring]: {
    consentTypeContentText: <>recurring ACH Debit Authorization</>,
    consentTypeText: <>Recurring payment</>,
    instalmentConsentTypeText: <>Recurring payment total</>,
    toolTipContent: (
      <Text>
        A <Text weight={FontWeight.Bold}>Recurring payment</Text> is set up to
        automatically process payments at regular intervals without any action
        needed from you. This is based on your debit authorization, which is
        provided to the merchant.
      </Text>
    ),
  },
  [ConsentType.Single]: {
    consentTypeContentText: <>one-time Pre-Authorized Debit Agreement</>,
    consentTypeText: <>One-time payment</>,
    toolTipContent: (
      <Text>
        A <Text weight={FontWeight.Bold}>One-time payment</Text> is a debit
        initiated by a merchant based on the payer&apos;s authorization for a
        one-time transfer of funds from the payer&apos;s account.
      </Text>
    ),
  },
  [ConsentType.Sporadic]: {
    consentTypeText: <>Sporadic</>,
    toolTipContent: (
      <Text>
        A <Text weight={FontWeight.Bold}>Sporadic Pre-Authorized Debit</Text>{" "}
        provides authorization to debit a payor&apos;s account on a sporadic
        basis (occasionally, irregularly, intermittently, infrequently,
        periodically) when triggered by a specified act, event or other
        criteria. Merchant shall obtain consent from the payor prior to each PAD
        via affirmative action by the payor and store evidence of that action.
      </Text>
    ),
  },
  [ConsentType.Standing]: {
    consentTypeContentText: <>standing ACH Debit Authorization</>,
    consentTypeText: <>Standing</>,
    toolTipContent: (
      <Text>
        A <Text weight={FontWeight.Bold}>Standing Authorization</Text> allows
        you to pre-approve future transactions that require your action to
        initiate. You will be informed about how you can authorize these
        transactions, which may include a phone call, online action, or a text
        message.
      </Text>
    ),
  },
};

export const DisplayedConsentInformation: DisplayedConsentInformationType = {
  [Scheme.Ach]: { ...baseDisplayedConsentInformation },
  [Scheme.Pad]: {
    ...baseDisplayedConsentInformation,
    [ConsentType.Recurring]: {
      ...baseDisplayedConsentInformation.recurring,
      consentTypeContentText: <>recurring Pre-Authorized Debit Agreement</>,
    },
  },
};
